import { useState, useContext, useCallback } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import useApi from '../../../shared/const/api-backend';
import {UserDataContext} from "../../../shared/context/userDataContext";
import { reportError } from 'shared/services/raygunService';

const useFetchCertificate = () => {
  const { dataCenter } = useContext(UserDataContext);
  const [error, setError] = useState(null);
  const [certificateData, setCertificateData] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const API = useApi();

  const fetchCertificateData = useCallback(async (policyId, catalogId) => {

    try {
      setLoading(true);
      const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/documents/platforms/${dataCenter.psPlatform}/catalogs/${catalogId}/policies/${policyId}/contract`;
      const response = await API.get(apiUrl);

      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      const responseData = response.data;
      setCertificateData(responseData);
      return responseData;
    } catch (error) {
      setError(error);
      showNotification('Error fetching certificate', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  }, [showNotification, setLoading]);

  return { certificateData, error, fetchCertificateData };
};

export default useFetchCertificate;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { IconButton } from '@mui/material';
import GenericTable from 'shared/components/GenericTable/genericTable';
import {format} from "date-fns";
import {CONFIG_APP} from "../../../../shared/const/app.config";
import CurrencyComponent from "../../../../shared/components/Currency/Currency";

const PoliciesTable = ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const path = 'POLICIES';

  const openDetails = (policyId, persistentQuoteId) => {
    const searchParams = new URLSearchParams()
    if (persistentQuoteId) searchParams.set('persistentQuoteId', persistentQuoteId)

    const url = `/policy-details/${policyId}?${searchParams.toString()}`;
    navigate(url);
  };
  const columns = [
    {
      label: `${t(`${path}.policyId`)}`,
      value: (policy) => `${policy.legacyPolicyId ? policy.legacyPolicyId : policy.policyId}`,
    },
    {
      label: `${t(`${path}.brand`)}`,
      value: (policy) => policy.brandCode,
    },
    {
      label: t(`${path}.customer`),
      value: (policy) => `${policy.customerFirstName} ${policy.customerLastName}`,
    },
    {
      label: t(`${path}.catalog`),
      value: (policy) => policy.catalog,
    },
    {
      label: t(`${path}.product`),
      value: (policy) => policy.product,
    },
    {
      label: t(`${path}.destination`),
      value: (policy) => policy.destination,
    },
    {
      label: t(`${path}.purchaseDate`),
      value: (policy) => format(new Date(policy.purchaseDate), CONFIG_APP.DATE_FORMAT),
    },
    {
      label: t(`${path}.amount`),
      value: (policy) => <CurrencyComponent value={policy.amount.amount} countryCode={policy.amount.currency}></CurrencyComponent>,
    },
    {
      label: t(`${path}.actions`),
      value: () => (
        <IconButton size='small' color='info'>
          <PlayCircleIcon />
        </IconButton>
      ),
      width: '70px'
    }
  ];

  return (
    <div className="PoliciesTable" data-testid="PoliciesTable">
      {!data || data.length === 0 ? (
        <p className='dataError'>{t(`${path}.noPolicies`)}</p>
      ) : (
        <GenericTable
          data={data}
          columns={columns}
          path={path}
          onRowClick={(policy) => openDetails(policy.policyId, policy.persistentQuoteId)}
        />
      )}
    </div>
  );
};

PoliciesTable.propTypes = {
  data: PropTypes.array.isRequired,
};

PoliciesTable.defaultProps = {};

export default PoliciesTable;

import React, { useContext } from "react";
import Paper from "@mui/material/Paper";
import { UserDataContext } from "../../../../shared/context/userDataContext";
import { getToken } from "../../../../shared/const/token.helper";
import useFetchPendingQuotes from "../../pending-quotes/useFetchPendingQuotes";
import { AuthContext } from "../../../../AuthContext";

const WelcomeGetQuote = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const { dataCenter, userData } = useContext(UserDataContext);
  const { platform } = useContext(AuthContext);
  // @TODO needs to be refactored to use this value from Strapi
  const brand = platform ? "fctg" : "igo";
  const token = getToken();

  const iframeUrl = `${dataCenter.widgetUrl}?brand=${brand}&token=${token}`;

  return (
    <>
      {dataCenter.widgetUrl && <Paper style={{ minHeight: "100px", marginBottom: "1rem" }} className="welcome-get-quote">
        <iframe src={iframeUrl}></iframe>
      </Paper>}
    </>
  );
};

export default WelcomeGetQuote;

import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './paymentTab.scss';
import { Grid } from '@mui/material';
import { format } from 'date-fns';
import CurrencyComponent from "../../../../shared/components/Currency/Currency";

const PaymentTab = ({ policyDetails }) => {
  const { t } = useTranslation();
  const discretionaryDiscount = { 
    value: policyDetails?.policy.genericData.dd_amount || policyDetails?.policy.genericData.dd_rate,
    isPercentageValue: !!policyDetails?.policy.genericData.dd_rate
  };
  const getInitialValues = () => {
    return {
      grossPremium: <CurrencyComponent value={policyDetails?.legacyPolicyId ? policyDetails.policy.value5 : policyDetails.policy.price?.grossPremium} countryCode={policyDetails.policy.price.currency} />,
      status: policyDetails?.payment.status,
      netPrice: <CurrencyComponent value={policyDetails.policy.price.netPrice} countryCode={policyDetails.policy.price.currency} />,
      paymentDate: policyDetails?.policy?.subscriptionDate && format(new Date(policyDetails?.policy?.subscriptionDate), "dd.MM.yyyy"),
      tax: <CurrencyComponent value={policyDetails.policy.price.tax} countryCode={policyDetails.policy.price.currency} />,
      provider: policyDetails?.payment.provider,
      grossPremiumBeforePromotion: <CurrencyComponent value={policyDetails.policy.price.grossPremiumBeforePromotion} countryCode={policyDetails.policy.price.currency} />,
      promotion: policyDetails?.policy.price.promotion ? t('COMMON.yes') : t('COMMON.no'),
      currency: policyDetails?.policy.price.currency,
      promotionCode: policyDetails?.policy.price.promotionCode,
      reference: policyDetails?.policy.value10,
      paymentType: policyDetails?.legacyPolicyId ? policyDetails?.genericData?.policyPaymentType : policyDetails?.policy.value9,
      medicalCharge: policyDetails?.policy.additionalValues[0]?.data.medical_increase !== undefined && policyDetails?.policy.additionalValues[0]?.data.medical_increase !== "" ? `${policyDetails.policy.additionalValues[0].data.medical_increase} ${policyDetails.policy.price.currency}` : undefined,
      creditAmount: policyDetails?.policy.genericData.creditAmount !== undefined && policyDetails?.policy.genericData.creditAmount !== "" ? <CurrencyComponent value={policyDetails?.policy.genericData.creditAmount} countryCode={policyDetails.policy.price.currency}></CurrencyComponent> : undefined,
      discountAmount: policyDetails ? <CurrencyComponent value={policyDetails.policy.price.grossPremiumBeforePromotion - policyDetails.policy.price.grossPremium} countryCode={policyDetails.policy.price.currency} /> : undefined,
      discretionaryDiscount: (discretionaryDiscount.value !== undefined && discretionaryDiscount.value !== "" && (discretionaryDiscount.isPercentageValue ? `${discretionaryDiscount.value * 100}%` : <CurrencyComponent value={discretionaryDiscount.value} countryCode={policyDetails.policy.price.currency}></CurrencyComponent>)) || undefined,
      refundAmount: policyDetails?.policy.value6 !== undefined && policyDetails?.policy.value6 !== "" && !policyDetails?.policy.value6.includes("/") ? <CurrencyComponent value={policyDetails.policy.value6} countryCode={policyDetails.policy.price.currency}></CurrencyComponent> : undefined,
    };
  };

  const initialValues = getInitialValues();
  const nonEmptyInitialValues = Object.fromEntries(Object.entries(initialValues).filter(([_, value]) => value !== undefined));

  return (
    <div className="paymentTab">
      <div className="header">
        <h4>{t('POLICY_DETAILS.tabPayment')}</h4>
      </div>
      <Grid container spacing={2} mb={4}>
        {Object.entries(nonEmptyInitialValues).map(([name, value], index) => (
          <Grid item xs={6} key={index} className="field-container">
            <div className="label-text">
              {t(`POLICY_DETAILS.PAYMENT.${name}`)}:
            </div>
            <div className="text-value">
              {value}
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

PaymentTab.propTypes = {
  policyDetails: PropTypes.object,
};

export default PaymentTab;

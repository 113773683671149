import React from "react";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
// import EventIcon from '@mui/icons-material/Event';
// import DescriptionIcon from '@mui/icons-material/Description';
// import BuildIcon from '@mui/icons-material/Build';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Icon from "@mui/material/Icon";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import "./welcomeQuickLinks.scss";
import typography from "../../../../assets/theme/base/typography";

const WelcomeQuickLinks = (quickLinks) => {
  const { t } = useTranslation();
  const { size } = typography;

  const handleClickLink = (link) => {
    alert("Link clicked -> more info needed how to handle these links");
  };

  return (
    <Paper className="quick-links-container" style={{ minHeight: "100px", marginBottom: "1rem" }}>
      <SoftTypography component="span" color="dark" fontSize={size.md} fontWeight="bold">
        {t("WELCOME_PAGE.QUICK_LINKS.title")}
      </SoftTypography>

      <ul className="quick-links">
        {quickLinks?.data &&
          quickLinks.data.map((link) => (
            <li key={link.icon} onClick={() => handleClickLink(link)}>
              <SoftBox
                bgColor="error"
                width="1.4rem"
                height="1.4rem"
                borderRadius="md"
                color="white"
                fontSize={size.xs}
                display="flex"
                justifyContent="center"
                alignItems="center"
                shadow="md"
                variant="gradient"
              >
                <Icon>{link.icon}</Icon>
              </SoftBox>
              <SoftTypography
                className="quick-link-title"
                component="span"
                variant="body2"
                color="text"
                fontSize={size.sm}
              >
                {link.title}
              </SoftTypography>
              <Icon className="open-right-icon" fontSize="small" color="error">
                <ChevronRightIcon />
              </Icon>
            </li>
          ))}
      </ul>
    </Paper>
  );
};

export default WelcomeQuickLinks;

import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "AuthContext";
import { LoaderContext } from "shared/context/loaderContext";
import { NotificationContext } from "shared/context/notificationContext";
import { UserDataContext } from "shared/context/userDataContext";
import { reportError } from "shared/services/raygunService";
import PropTypes from "prop-types";

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

function Redirect({ provider }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { platform, saveToken } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const { fetchDataCenterFromApi } = useContext(UserDataContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const callbackUrl = `${apiStrapiUrl}auth/${provider}/callback${location.search}`;
        const response = await fetch(callbackUrl);

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const jsonResponse = await response.json();
        saveToken(jsonResponse.jwt);
        showNotification("NOTIFICATION.success.login", "success");
        fetchDataCenterFromApi();
        navigate("/dashboard");
        setLoading(false);
      } catch (error) {
        setLoading(false);
        showNotification("COMMON.error.fetchingData", "error");
        reportError(error);
      }
    }
    fetchData();
  }, [location.search, navigate, saveToken, showNotification, setLoading, platform, provider]);

  return null;
}

Redirect.propTypes = {
  provider: PropTypes.oneOf(["microsoft", "okta"]),
};

export default Redirect;

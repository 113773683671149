import React from 'react';
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import PieChart from "examples/Charts/PieChart";
import { Icon, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ProductsWidget = ({ data, isOpened, openWidget, closeWidget }) => {
    const { t } = useTranslation();
    const isMediumScreen = useMediaQuery('(max-width:1480px)');
    return isOpened ? (
        <Card
            className="dashboard-widget products-widget dashboard-widget--opened"
        >
            <SoftBox className='widget-header'>
                <SoftTypography className='widget-header__title'>{t('HOME.DASHBOARD.widgets.productSplit')}</SoftTypography>
                <SoftTypography onClick={() => closeWidget()} className='widget-header__button'>{t('HOME.DASHBOARD.widgets.minimize')}</SoftTypography>
            </SoftBox>
            <SoftBox sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', pb: 2 }}>
                <SoftBox sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={`products-legend ${data.chart.labels.length > 6 ? 'products-legend__many-items' : ''}`}>
                    <SoftBox>
                        {
                            data.chart.labels.map((label, i) => {
                                return (<SoftBox key={label} sx={{ display: 'flex', gap: 1, alignItems: 'bottom' }}>
                                    <SoftBox className="products-legend__color">
                                        <Icon color={data.chart.datasets.backgroundColors[i]} sx={{ marginBottom: '-24px' }}>square</Icon>
                                    </SoftBox>
                                    <SoftBox className="products-legend__name" sx={{ fontSize: '40px' }}>{label} - </SoftBox>
                                    <SoftBox className="products-legend__value" sx={{ fontSize: '40px', fontWeight: 'bold' }}>{data.chart.datasets.data[i]}%</SoftBox>
                                </SoftBox>)
                            })
                        }
                    </SoftBox>
                </SoftBox>
                <PieChart
                    chart={data.chart}
                    height='24rem'
                />
            </SoftBox>
        </Card>
    ) :
        (
            <Card
                className="dashboard-widget products-widget dashboard-widget--closed"
            >
                <SoftBox className='widget-header'>
                    <SoftTypography className='widget-header__title'>{t('HOME.DASHBOARD.widgets.productSplit')}</SoftTypography>
                    <SoftTypography onClick={() => openWidget()} className='widget-header__button'>{t('HOME.DASHBOARD.widgets.maximize')}</SoftTypography>
                </SoftBox>
                <SoftBox sx={{ display: 'flex' }}>
                    <SoftBox sx={{ width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <SoftBox sx={{ maxWidth: '100%' }} className={`products-legend ${isMediumScreen ? 'products-legend__medium-screen' : ''} ${data.chart.labels.length > 6 ? 'products-legend__many-items' : ''}`}>
                            {
                                data.chart.labels.map((label, i) => {
                                    return (<SoftBox key={label} sx={{ display: 'flex', gap: 1, alignItems: 'bottom' }}>
                                        <SoftBox className="products-legend__color">
                                            <Icon color={data.chart.datasets.backgroundColors[i]} sx={{ marginBottom: '-3px' }}>square</Icon>
                                        </SoftBox>
                                        <SoftBox className="products-legend__name">{label} - </SoftBox>
                                        <SoftBox className="products-legend__value">{data.chart.datasets.data[i]}%</SoftBox>
                                    </SoftBox>)
                                })
                            }
                        </SoftBox>
                    </SoftBox>
                    <SoftBox
                        sx={{ width: '50%', padding: '0' }}
                    >
                        <PieChart
                            chart={data.chart}
                            height={isMediumScreen ? '10rem' : '14rem'}
                        />
                    </SoftBox>
                </SoftBox>
            </Card>
        )
}

ProductsWidget.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.shape({
            labels: PropTypes.arrayOf(PropTypes.string).isRequired,
            datasets: PropTypes.shape({
                backgroundColors: PropTypes.arrayOf(PropTypes.string).isRequired,
                data: PropTypes.arrayOf(PropTypes.number).isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    isOpened: PropTypes.bool,
    openWidget: PropTypes.func,
    closeWidget: PropTypes.func,
};


export default ProductsWidget;

import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import SoftBox from "../../../../components/SoftBox";
import typography from "../../../../assets/theme/base/typography";
import "./welcomeQuestions.scss";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl } from "@mui/material";
import SoftButton from "../../../../components/SoftButton";
import { useTranslation } from "react-i18next";
import SoftTypography from "../../../../components/SoftTypography";
import SoftInput from "../../../../components/SoftInput";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import MailIcon from "@mui/icons-material/Mail";
import { Controller, useForm } from "react-hook-form";

const WelcomeQuestions = () => {
  const { t } = useTranslation();
  const { size } = typography;
  const [isQuestionDialog, setIsQuestionDialog] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
    mode: "onBlur", // Validate on blur
  });

  const handleContactSalesClick = () => {
    setIsQuestionDialog(true);
  };

  const onSubmit = (data) => {
    // Perform API call here
    // Example: const response = await fetch('your-api-url', { method: 'POST', body: JSON.stringify(data) });
    alert("More information is needed to whom this email should be sent");
    // setIsQuestionDialog(false);
    reset();
  };

  return (
    <>
      <Paper className="welcome-questions-container" style={{ minHeight: "100px" }}>
        <SoftBox
          bgColor="white"
          width="1.75rem"
          height="1.75rem"
          borderRadius="md"
          color="error"
          fontSize={size.lg}
          display="flex"
          justifyContent="center"
          alignItems="center"
          shadow="md"
          variant="gradient"
        >
          <Icon>star</Icon>
        </SoftBox>
        <Box>
          <SoftTypography
            fontSize={size.md}
            fontWeight="bold"
            color="dark"
            sx={{ marginTop: "0.5rem" }}
          >
            {t("WELCOME_PAGE.QUESTIONS.title")}
          </SoftTypography>
          <SoftTypography
            fontSize={size.sm}
            color="dark"
            sx={{ marginBottom: "1rem" }}
            lineHeight="1rem"
          >
            {t("WELCOME_PAGE.QUESTIONS.description")}
          </SoftTypography>
          <SoftButton
            variant="outlined"
            color="info"
            size="small"
            fullWidth
            onClick={handleContactSalesClick}
          >
            {t("WELCOME_PAGE.QUESTIONS.button")}
          </SoftButton>
        </Box>
      </Paper>
      <Dialog open={isQuestionDialog} onClose={() => setIsQuestionDialog(false)} fullWidth>
        <DialogTitle>
          <SoftTypography>
            <MailIcon sx={{ paddingTop: "0.3rem" }} /> {t("WELCOME_PAGE.QUESTIONS.DIALOG.title")}
          </SoftTypography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <SoftTypography component="label" variant="caption" fontWeight="bold" mt={1} mb={0.5}>
              {t("WELCOME_PAGE.QUESTIONS.DIALOG.name")}
            </SoftTypography>
            <Controller
              name="name"
              control={control}
              rules={{ required: t("WELCOME_PAGE.QUESTIONS.DIALOG.error.name") }}
              render={({ field }) => <SoftInput {...field} error={!!errors.name} />}
            />
            {errors.name && (
              <SoftTypography className="field-error" color="error">
                {errors.name.message}
              </SoftTypography>
            )}
          </FormControl>
          <FormControl fullWidth>
            <SoftTypography component="label" variant="caption" fontWeight="bold" mt={0.5}>
              {t("WELCOME_PAGE.QUESTIONS.DIALOG.email")}
            </SoftTypography>
            <Controller
              name="email"
              control={control}
              rules={{
                required: t("WELCOME_PAGE.QUESTIONS.DIALOG.error.emailRequired"),
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: t("WELCOME_PAGE.QUESTIONS.DIALOG.error.emailFormat"),
                },
              }}
              render={({ field }) => <SoftInput {...field} error={!!errors.email} />}
            />
            {errors.email && (
              <SoftTypography className="field-error" color="error">
                {errors.email.message}
              </SoftTypography>
            )}
          </FormControl>
          <FormControl fullWidth>
            <SoftTypography component="label" variant="caption" fontWeight="bold" mt={0.5}>
              {t("WELCOME_PAGE.QUESTIONS.DIALOG.message")}
            </SoftTypography>
            <Controller
              name="message"
              control={control}
              rules={{ required: t("WELCOME_PAGE.QUESTIONS.DIALOG.error.message") }}
              render={({ field }) => (
                <SoftInput {...field} error={!!errors.message} multiline rows={3} maxRows={8} />
              )}
            />
            {errors.message && (
              <SoftTypography className="field-error" color="error">
                {errors.message.message}
              </SoftTypography>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <SoftButton size="small" onClick={() => setIsQuestionDialog(false)}>
            <CloseIcon sx={{ marginRight: "0.2rem" }} />
            {t("COMMON.close")}
          </SoftButton>
          <SoftButton size="small" color="success" onClick={handleSubmit(onSubmit)}>
            <CheckIcon sx={{ marginRight: "0.2rem" }} />
            {t("COMMON.send")}
          </SoftButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WelcomeQuestions;

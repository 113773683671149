/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.0
=========================================================

* Product Page: https://material-ui.com/store/items/soft-ui-pro-dashboard/
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Soft UI Dashboard PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav.
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
 */

// Soft UI Dashboard PRO React layouts

import NewQuote from "./layouts/home/new-quote/new-quote";
import PendingQuotes from "./layouts/home/pending-quotes/pending-quotes";
import Customers from "./layouts/home/customers/customers";
import Policies from "./layouts/home/policies/policies";
import MyProfile from "./layouts/home/my-profile/my-profile";
import Users from "./layouts/users/users";
import DashboardEmpty from "./layouts/home/dashboard-empty/dashboard-empty";
import HomeIcon from "@mui/icons-material/Home";
import FavoriteIcon from "@mui/icons-material/Favorite";
import AddBoxIcon from "@mui/icons-material/AddBox";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PeopleIcon from "@mui/icons-material/People";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Quote from "./layouts/home/quote/quote";
import CustomerDetails from "layouts/home/customerDetails/customerDetails";
import PolicyDetails from "layouts/home/policyDetails/policyDetails";
import DiscountApproval from "layouts/home/discount-approval/discount-approval";
import WelcomePage from "./layouts/home/welcomePage/welcomePage";
import QuoteExternal from "./layouts/home/quoteExternal/quoteExternal";
import Dashboard from "layouts/home/dashboard/dashboard";
import GetQuote from "layouts/home/get-quote/get-quote";


const routes = [
  {
    type: "collapse",
    name: "Welcome page",
    key: "welcome-page",
    route: "/welcome-page",
    component: <WelcomePage />,
    icon: <FavoriteIcon size="10px" />,
    noCollapse: true,
    visibleForBrand: "fctg",
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <Dashboard />,
    icon: <HomeIcon size="10px" />,
    noCollapse: true,
  },
  { type: "title", title: "QUOTES", key: "quotes" },
  {
    type: "collapse",
    name: "New Quote",
    key: "new-quote",
    route: "/new-quote",
    component: <NewQuote />,
    icon: <AddBoxIcon size="10px" />,
    noCollapse: true,
    visibleForBrand: "puzzle",
  },
  {
    type: "collapse",
    name: "Get a Quote",
    key: "new-quote",
    route: "/new-quote",
    component: <GetQuote />,
    icon: <AddBoxIcon size="10px" />,
    noCollapse: true,
    visibleForBrand: "fctg",
  },
  {
    type: "collapse",
    name: "Quotes",
    key: "pending-quotes",
    route: "/pending-quotes",
    component: <PendingQuotes />,
    icon: <NoteAddIcon size="10px" />,
    noCollapse: true,
  },
  { type: "title", title: "CUSTOMER MANAGEMENT", key: "customer-management" },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    route: "/customers",
    component: <Customers />,
    icon: <PeopleIcon size="10px" />,
    noCollapse: true,
  },
  {
    route: "/customer-details/:customerId/:country",
    component: <CustomerDetails />,
  },
  {
    type: "collapse",
    name: "Policies",
    key: "policies",
    route: "/policies",
    component: <Policies />,
    icon: <FileCopyIcon size="10px" />,
    noCollapse: true,
  },
  {
    route: "/policy-details/:policyId",
    component: <PolicyDetails />,
  },
  { type: "title", title: "MY ACCOUNT", key: "account" },
  {
    type: "collapse",
    name: "My Profile",
    key: "my-profile",
    route: "/my-profile",
    component: <MyProfile />,
    icon: <AccountCircleIcon size="10px" />,
    noCollapse: true,
  },
  { type: "divider", key: "divider-1", roles: ["admin"] },
  { type: "title", title: "ADMIN", key: "admin", roles: ["admin"] },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/admin/users",
    component: <Users />,
    icon: <HomeIcon size="10px" />,
    noCollapse: true,
    roles: ["admin"],
  },
  {
    route: "/quote",
    component: <Quote />,
  },
  {
    route: "/quote-external",
    component: <QuoteExternal />,
  },
  {
    type: "collapse",
    key: "discount",
    route: "/discount",
    component: <DiscountApproval />,
    noCollapse: true,
    roles: ["agent", "admin"],
  },
];

export default routes;

import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import SoftTypography from "../../../../components/SoftTypography";
import "./welcomeMessage.scss";

const WelcomeMessage = ({data = ''}) => {
  return (
    <Paper
      className="welcome-container-right welcome-message"
      style={{ minHeight: "100px", marginBottom: "1rem" }}
    >
      <SoftTypography component="span" variant="body1" color="text" fontWeight="medium">
        {data}
      </SoftTypography>
    </Paper>
  );
};

WelcomeMessage.propTypes = {
  data: PropTypes.string,
};

export default WelcomeMessage;

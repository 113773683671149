import React, { useEffect, useState } from "react";
import WelcomeBrandBanner from "./welcomeBrandBanner/welcomeBrandBanner";
import { Box, Grid } from "@mui/material";
import WelcomeTravelAlerts from "./welcomeTravelAlerts/welcomeTravelAlerts";
import WelcomeMessage from "./welcomeMessage/welcomeMessage";
import WelcomeQuickLinks from "./welcomeQuickLinks/welcomeQuickLinks";
import WelcomeQuestions from "./welcomeQuestions/welcomeQuestions";
import WelcomeGetQuote from "./welcomeGetQuote/welcomeGetQuote";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { getToken } from "../../../shared/const/token.helper";
import "./welcomePage.scss";

const apiStrapiUrl = "http://localhost:1337/api/";
const token = getToken();

const WelcomePage = () => {
  const [data, setData] = useState(null);
  // @TODO globalize userLanguage and selectedBrand
  const userLanguage = "en";
  const selectedBU = "fctg";
  const selectedBrand = "fctg";
  const [isWidgetOnly, setIsWidgetOnly] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `${apiStrapiUrl}custom-endpoint`;
        // const apiUrl = `${apiStrapiUrl}eshop-brand?businessUnitParam=${selectedBU}&eshopBrandParam=${selectedBrand}`;
        // const response = await axios.get(apiUrl, {
        //   headers: {
        //     Authorization: `Bearer b9d03573fb413900145e10d4721517c8c4d5c3df88a118d6aa2b99bdfca7973243b9afe32ef197918947a6d2438925fdd1a74affaf17a56c0283ab2ad472240aee1f70db306454193fa04c56968d419a463d1049495c6540f95956f97d8dbcbbcea0a09319c078c0748649fce2444bd6451403b88afe91f3146c8b7953c51aec`,
        //   },
        // });
        // const formattedData = strapiExtractAttributes(response.data);
        // console.log("formattedData, formattedData", formattedData[0]);
        const mockData = {
          name: "FCTG",
          code: "fctg",
          businessUnit: "fctg",
          landingPage: {
            travelAlerts:
              "At Europ Assistance we believe it is our role to assist our customers who are in distress",
            welcomeMessage: "Welcome",
            quickLinks: [
              {
                icon: "event",
                link: "/important-updates",
                title: "Important Updates",
              },
              {
                icon: "description",
                link: "/pgs-fsgs",
                title: "PDS/FSGs",
              },
              {
                icon: "build",
                link: "/europe-assistance-customer-support",
                title: "Europe Assistance Customer Support",
              },
              {
                icon: "trending_up",
                link: "/sales-team-contacts",
                title: "Sales Team contacts",
              },
            ],
          },
          createdAt: "2024-05-31T10:52:26.307Z",
          updatedAt: "2024-05-31T10:53:20.263Z",
          publishedAt: "2024-05-31T10:53:20.259Z",
        };
        setData(mockData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const handleMessage = (event) => {
      if (event.data && event.data.type === 'GET_A_QUOTE') {
        setIsWidgetOnly(true);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box sx={{ flexGrow: 1 }} className={`welcome-page ${isWidgetOnly ? 'welcome-page--widget-only' : ''}`}>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <WelcomeBrandBanner />
          </Grid>
          <Grid item md={8} sm={12}>
            <WelcomeTravelAlerts data={data?.landingPage?.travelAlerts} />
            <WelcomeGetQuote />
          </Grid>
          <Grid item md={4} sm={12}>
            <WelcomeMessage data={data?.landingPage?.welcomeMessage}></WelcomeMessage>
            <WelcomeQuickLinks data={data?.landingPage?.quickLinks}></WelcomeQuickLinks>
            <WelcomeQuestions></WelcomeQuestions>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default WelcomePage;

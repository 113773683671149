import { useState, useContext, useCallback } from 'react';
import { NotificationContext } from 'shared/context/notificationContext';
import { LoaderContext } from 'shared/context/loaderContext';
import useApi from '../../../shared/const/api-backend';
import {UserDataContext} from "../../../shared/context/userDataContext";
import { reportError } from 'shared/services/raygunService';

const useFetchMedicalScreening = () => {
  const { dataCenter } = useContext(UserDataContext);
  const [error, setError] = useState(null);
  const [medicalScreeningData, setMedicalScreeningData] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const API = useApi();

  const fetchMedicalScreeningData = useCallback(async (companionId) => {
    try {
      setLoading(true);
      const apiUrl = `${dataCenter.cm360Endpoint}/medical-screenings/${companionId}?filter=conditions`;
      const headers = {
        'X-Api-Key': dataCenter.cm360Key
      };
      const response = await API.get(apiUrl, { headers });
      setMedicalScreeningData(response.data);
      return response.data;
    } catch (error) {
      setError(error);
      showNotification('Error fetching medical screening data', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  }, [showNotification]);

  return { medicalScreeningData, error, fetchMedicalScreeningData };
};

export default useFetchMedicalScreening;

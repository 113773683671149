import {useContext, useState} from "react";
import useDidMountEffect from "shared/utils/useDidMountEffect";
import {LoaderContext} from "../../../shared/context/loaderContext";
import {NotificationContext} from "../../../shared/context/notificationContext";
import {CONFIG_TABLE} from "../../../shared/const/app.config";
import { UserDataContext } from "shared/context/userDataContext";
import useApi from "../../../shared/const/api-backend";
import { reportError } from "shared/services/raygunService";

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

const useFetchCustomersData = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const API = useApi();

  const fetchData = async (firstName, lastName, email) => {
    setLoading(true);
    try {
      let apiUrl = `${apiStrapiUrl}customers?offset=0&limit=${CONFIG_TABLE.MAX_ROWS_FROM_BACKEND}`;
      if (firstName) {
        apiUrl += `&firstname=${firstName}`;
      }
      if (lastName) {
        apiUrl += `&lastname=${lastName}`;
      }
      if (email) {
        apiUrl += `&email=${email}`;
      }

      const response = await API.get(apiUrl);
      setData(response.data);
    } catch (error) {
      showNotification("COMMON.error.fetchingData", "error");
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  useDidMountEffect(() => {
    if (!dataCenter || dataCenter.length === 0) {
      showNotification("COMMON.error.noDataCenterSelected", "error");
      return;
    };
    fetchData();
  }, [dataCenter]);

  return { data, error, fetchData };
};

export default useFetchCustomersData;

import React from 'react';
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import { IconButton } from '@mui/material';
import GenericTable from 'shared/components/GenericTable/genericTable';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useTranslation } from 'react-i18next';
import CurrencyComponent from 'shared/components/Currency/Currency';

const ExpiringQuotesWidget = ({ data, isOpened, openWidget, closeWidget }) => {

    const { t } = useTranslation();
    const path = 'HOME.PENDING_QUOTES.table.header';

    const openQuote = (row) => {
        const url = `/quote?brand=${row.brand}&agent=true&quoteId=${row.quoteId}`
        window.location.href = url;
    }

    const minimizedTableColumns = [

        {
            label: t(`${path}.quoteId`),
            value: (row) => row.quoteId,
        },
        {
            label: t(`${path}.brand`),
            value: (row) => row.brand
        },
        {
            label: t(`${path}.product`),
            value: (row) => row.type
        },
        {
            label: t(`${path}.exp`),
            value: (row) => row.expireTime,
        },
        {
            label: t(`${path}.actions`),
            value: () => (
                <IconButton size='small' color='info'>
                    <PlayCircleIcon />
                </IconButton>
            ),
            width: '70px'
        }

    ];
    const tableColumns = [
        {
            label: t(`${path}.quoteId`),
            value: (row) => row.quoteId,
        },
        {
            label: t(`${path}.brand`),
            value: (row) => row.brand
        },
        {
            label: t(`${path}.customer`),
            value: (row) => row.customerName,
        },
        {
            label: t(`${path}.catalog`),
            value: (row) => row.catalog
        },
        {
            label: t(`${path}.product`),
            value: (row) => row.type
        },
        {
            label: t(`${path}.destination`),
            value: (row) => row.destination
        },
        {
            label: t(`${path}.exp`),
            value: (row) => row.expireTime,
        },
        {
            label: t(`${path}.step`),
            value: (row) => (
                <span style={{ color: row.step === '4-PAYMENT' ? 'green' : 'black', whiteSpace: 'nowrap' }}>
                    {row.step}
                </span>
            ),
            width: '170px'
        },
        {
            label: t(`${path}.value`),
            value: (row) => <CurrencyComponent value={row.amount?.amount} countryCode={row.amount?.currency}></CurrencyComponent>,
        },
        {
            label: t(`${path}.actions`),
            value: () => (
                <IconButton size='small' color='info'>
                    <PlayCircleIcon />
                </IconButton>
            ),
            width: '70px'
        }
    ];

    return isOpened ? (
        <Card
            className="dashboard-widget expiring-quotes-widget dashboard-widget--opened"
        >
            <SoftBox className='widget-header'>
                <SoftTypography className='widget-header__title'>{t('HOME.DASHBOARD.widgets.expiringQuotes')}</SoftTypography>
                <SoftTypography onClick={() => closeWidget()} className='widget-header__button'>{t('HOME.DASHBOARD.widgets.minimize')}</SoftTypography>
            </SoftBox>
            <SoftBox>
                <GenericTable
                    data={data?.tableData}
                    columns={tableColumns}
                    path={path}
                    onRowClick={openQuote}
                />
            </SoftBox>
            <SoftBox className='widget-footer'>
                <SoftBox className='widget-footer__text'>
                    <SoftTypography component="p">
                        {t('HOME.DASHBOARD.widgets.totalQuotes')}:
                    </SoftTypography>
                    <SoftTypography component="span">{data.total}</SoftTypography>
                </SoftBox>
            </SoftBox>
        </Card>
    ) :
        (
            <Card
                className="dashboard-widget expiring-quotes-widget dashboard-widget--closed"
            >
                <SoftBox className='widget-header'>
                    <SoftTypography className='widget-header__title'>{t('HOME.DASHBOARD.widgets.expiringQuotes')}</SoftTypography>
                    <SoftTypography onClick={() => openWidget()} className='widget-header__button'>{t('HOME.DASHBOARD.widgets.maximize')}</SoftTypography>
                </SoftBox>
                <SoftBox>
                    <GenericTable
                        data={data?.tableData.slice(0, 4)}
                        columns={minimizedTableColumns}
                        path={path}
                        onRowClick={openQuote}
                    />
                </SoftBox>
                <SoftBox className='widget-footer'>
                    <SoftBox className='widget-footer__text'>
                        <SoftBox component="p">
                            {t('HOME.DASHBOARD.widgets.totalQuotes')}:
                        </SoftBox>
                        <SoftBox component="span">
                            {data.total}
                        </SoftBox>
                    </SoftBox>
                </SoftBox>
            </Card>
        )
}

ExpiringQuotesWidget.propTypes = {
    data: PropTypes.shape({
        tableData: PropTypes.array.isRequired,
        total: PropTypes.number.isRequired,
    }).isRequired,
    isOpened: PropTypes.bool.isRequired,
    openWidget: PropTypes.func.isRequired,
    closeWidget: PropTypes.func.isRequired,
};


export default ExpiringQuotesWidget;

import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import './pending-quotes-table.scss';
import { IconButton } from "@mui/material";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import GenericTable from "shared/components/GenericTable/genericTable";
import {format} from "date-fns";
import {CONFIG_APP} from "../../../../shared/const/app.config";
import CurrencyComponent from "../../../../shared/components/Currency/Currency";

const PendingQuotesTable = ({ data }) => {
    const { t } = useTranslation();
    const path = 'HOME.PENDING_QUOTES.table.header';

    const openQuote = (row) => {
        const url = `/quote?brand=${row.brandCode}&agent=true&quoteId=${row.quoteId}`
        window.location.href = url;
    }

    const columns = [
        {
            label: `${t(`${path}.quoteId`)}`,
            value: (row) => row.quoteId
        },
        {
            label: `${t(`${path}.brand`)}`,
            value: (row) => row.brandCode
        },
        {
            label: t(`${path}.customer`),
            value: (row) => {
                const firstName = row.customerFirstName || '';
                const lastName = row.customerLastName || '';
                return `${firstName} ${lastName}`;
            },
        },
        {
            label: t(`${path}.catalog`),
            value: (row) => row.catalog
        },
        {
            label: t(`${path}.product`),
            value: (row) => row.product
        },
        {
            label: t(`${path}.destination`),
            value: (row) => row.destination
        },
        {
            label: t(`${path}.dateCreated`),
            value: (row) => format(new Date(row.createdDate), CONFIG_APP.DATE_FORMAT)
        },
        {
            label: t(`${path}.exp`),
            value: (row) => (
                <span style={{ color: row.expiresInDays <= 3 ? 'red' : (row.expiresInDays >= 10 ? 'green' : 'black') }}>
                    {row.expiresInDays} <span style={{ display: row.expiresInDays ? 'inline' : 'none' }}>{t('HOME.PENDING_QUOTES.table.days')}</span>
                </span>
            ),
            width: '70px'
        },
        {
            label: t(`${path}.step`),
            value: (row) => (
                <span style={{ color: row.step === '4-PAYMENT' ? 'green' : 'black', whiteSpace: 'nowrap' }}>
                    {row.step}
                </span>
            ),
            width: '170px'
        },
        {
            label: t(`${path}.value`),
            value: (row) => <CurrencyComponent value={row.amount.amount} countryCode={row.amount.currency}></CurrencyComponent>,
        },
        {
            label: t(`${path}.actions`),
            value: () => (
                <IconButton size='small' color='info'>
                    <PlayCircleIcon />
                </IconButton>
            ),
            width: '70px'
        }
    ];

    return (
        <GenericTable
            data={data}
            columns={columns}
            path={path}
            onRowClick={openQuote}
        />
    );
};

PendingQuotesTable.propTypes = {
    data: PropTypes.array.isRequired,
};

PendingQuotesTable.defaultProps = {};

export default PendingQuotesTable;

import { useContext } from 'react';
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import useApi from '../../../shared/const/api-backend';
import {UserDataContext} from "../../../shared/context/userDataContext";
import { reportError } from 'shared/services/raygunService';

const useAddNote = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const API = useApi();

  const addNote = async (bookingId, content) => {
    setLoading(true);
    try {
      const apiUrl = `${dataCenter.cm360Endpoint}/booking/${bookingId}/notes`;
      const headers = {
        'X-Api-Key': dataCenter.cm360Key
      };
      await API.post(apiUrl, { content }, { headers });
      showNotification("Note added successfully", "success");
    } catch (error) {
      showNotification("Error adding note", "error");
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return { addNote };
};

export default useAddNote;

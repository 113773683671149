import { useState, useContext } from 'react';
import { LoaderContext } from "shared/context/loaderContext";
import { NotificationContext } from "shared/context/notificationContext";
import useApi from 'shared/const/api-backend';
import { UserDataContext } from "shared/context/userDataContext";
import dayjs from "dayjs";
import {CONFIG_APP} from "../../../../shared/const/app.config";
import { reportError } from 'shared/services/raygunService';
import { useTranslation } from 'react-i18next';

const useCalculateEndorsementPrice = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const [endorsementPrice, setEndorsementPrice] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const API = useApi();
  const { t } = useTranslation();

  const calculateEndorsementPrice = async (policy, endorsement) => {
    setLoading(true);

    try {
      const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogs/${policy.product.iac}/policies/${policy.policy.policyNumber}/endorsements/calculate`;
      const payload = {
        startDate: dayjs(endorsement.travelStartDate).format(CONFIG_APP.DATE_STRING_FORMAT),
        endDate: dayjs(endorsement.travelEndDate).format(CONFIG_APP.DATE_STRING_FORMAT),
      }

      const response = await API.post(apiUrl, payload);
      const responseData = response.data;
      setEndorsementPrice(responseData.price);

    } catch (error) {
      setError(error);
      showNotification(t(`POLICY_DETAILS.ENDORSEMENTS.errorCalculatingEndorsementPrice`), "error");
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  const resetEndorsementPrice = () => {
    setEndorsementPrice(null);
  };

  return { endorsementPrice, calculateEndorsementPrice, resetEndorsementPrice,  error };
};

export default useCalculateEndorsementPrice;

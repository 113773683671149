import {useContext, useEffect, useState} from 'react';
import {LoaderContext} from "../../../shared/context/loaderContext";
import {NotificationContext} from "../../../shared/context/notificationContext";
import useApi from 'shared/const/api-backend';
import {UserDataContext} from 'shared/context/userDataContext';
import {useLocation, useNavigate} from "react-router-dom";
import { reportError } from 'shared/services/raygunService';

const useFetchPolicyDetails = (policyId, persistentQuoteId) => {
  const { dataCenter } = useContext(UserDataContext);
  const [policyDetails, setPolicyDetails] = useState(null);
  const [persistentQuoteDetails, setPersistentQuoteDetails] = useState(null);
  const { setLoading } = useContext(LoaderContext);
  const [error, setError] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const { state } = useLocation();
  const navigate = useNavigate();
  const API = useApi();

  const fetchData = async () => {
    setLoading(true);
    try {
      if (Object.keys(dataCenter).length > 0) {
        const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogGroups/${dataCenter.catalogGroup}/policies/${policyId}`;
        const quoteApiUrl = persistentQuoteId
          ? `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogGroups/${dataCenter.catalogGroup}/quotes/${persistentQuoteId}`
          : null;

        const [response, quoteResponse] = await Promise.all([
          API.get(apiUrl),
          quoteApiUrl ? API.get(quoteApiUrl) : null,
        ]);

        const responseData = response.data;
        const quoteResponseData = quoteResponse?.data || null;

        setPolicyDetails(responseData);
        setPersistentQuoteDetails(quoteResponseData);
      }
    } catch (error) {
      setError(error);
      if (error.response.status === 404) {
        showNotification("COMMON.error.policyNotFound", "error");
      } else {
        showNotification("COMMON.error.fetchingData", "error");
      }
      navigate(-1);
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [policyId, dataCenter]);

  return { policyDetails, persistentQuoteDetails, error, fetchData };
};

export default useFetchPolicyDetails;

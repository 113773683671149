// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Authentication layout components
import authBackground from "assets/images/background.png";
import AuthLayout from "../components/AuthLayout";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import logo from "../../../assets/images/logo.svg";
import colors from "assets/theme/base/colors";

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

function BasicLogin() {
  const { buttons } = colors;

  const handleLogin = () => {
    window.location.href = `${apiStrapiUrl}connect/microsoft`;
  };

  return (
    <AuthLayout image={authBackground}>
      <Card>
        <SoftBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: "#fff",
            borderRadius: "16px",
            padding: "40px",
            width: "100%",
          }}
        >
          <Box
            component="img"
            src={logo}
            sx={{
              minWidth: "48%",
              mt: 2,
              mb: 4,
            }}
          />
          <Typography
            component="h1"
            variant="h3"
            sx={{ fontWeight: "bold", color: "#17238c" }}
          >
            eShop Portal
          </Typography>
          <SoftBox component="div" sx={{ mt: 3, width: "100%" }}>
            <SoftBox component="form" role="form">
              <SoftBox mb={2}>
                <SoftBox mt={2} mb={1}>
                  <SoftButton
                    onClick={handleLogin}
                    variant="gradient"
                    sx={{
                      mb: 3,
                      textTransform: "none",
                      backgroundColor: buttons.secondary.background,
                      "&:hover": {
                        backgroundColor: buttons.secondary.background,
                      },
                      color: buttons.secondary.text,
                    }}
                    fullWidth
                  >
                    Azure login
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftTypography
            variant="body2"
            sx={{ fontSize: "0.8rem" }}
            color="dark"
          >
            Let&apos;s make today beautiful. Again.
          </SoftTypography>
        </SoftBox>
      </Card>
    </AuthLayout>
  );
}

export default BasicLogin;

import React from 'react';
import PropTypes from "prop-types";
import DefaultCell from "../../-removed/layouts/ecommerce/products/product-page/components/DefaultCell";

export const NotificationContext = React.createContext();

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = React.useState(null);

    const showNotification = (message, type = 'info') => {
        setNotification({ message, type });
        setTimeout(() => {
            setNotification(null);
        }, 5000); // Notification will hide after 3 seconds
    };

    return (
        <NotificationContext.Provider value={{ notification, showNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};

NotificationProvider.propTypes = {
    children: PropTypes.node,
};

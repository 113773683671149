import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [platform, setPlatform] = useState(
    localStorage.getItem("platform") ? localStorage.getItem("platform") : "puzzle"
  );

  // After first render, get the token from localStorage
  useEffect(() => {
    const localToken = localStorage.getItem("token");
    if (localToken) {
      setToken(localToken);
    }
  }, []);
  const saveToken = (newToken, platform) => {
    localStorage.setItem("token", newToken);
    setToken(newToken);
    if (platform) {
      localStorage.setItem("platform", platform);
      setPlatform(platform);
    }
  };

  const value = {
    token,
    platform,
    saveToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

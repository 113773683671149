import React from 'react';
import PropTypes from 'prop-types';
import styles from '../customerDetails.module.scss';
import PoliciesTable from 'layouts/home/policies/policies-table.js/policies-table';

const PoliciesTab = ({ policies }) => {
  return (
    <div className={styles.PoliciesTab} data-testid="PoliciesTab">
      <PoliciesTable data={policies} />
    </div>
  );
};

PoliciesTab.propTypes = {
    policies: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PoliciesTab;
import React, { useContext } from "react";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import Grid from "@mui/material/Grid";
import { getToken } from "shared/const/token.helper";
import { UserDataContext } from "../../../shared/context/userDataContext";
import { AuthContext } from "../../../AuthContext";

const QuoteExternal = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const { dataCenter } = useContext(UserDataContext);
  const { platform } = useContext(AuthContext);
  // @TODO change this to the brand from the user context
  const brand = platform ? "fctg" : "igo";
  const allowedParams = [
    "currentStep",
    "catalog",
    "destination",
    "startDate",
    "endDate",
    "travellersType",
    "companion1",
    "options",
    "addons",
    "product",
    "externalBacklink",
    "externalBookingId",
  ];
  const paramsObject = {};
  urlParams.forEach((value, key) => {
    if (allowedParams.includes(key)) {
      paramsObject[key] = value;
    }
  });

  const createQueryString = (params) => {
    return Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join("&");
  };

  const token = getToken();
  const iframeUrl = `${dataCenter.widgetUrl}?brand=${brand}&${createQueryString(
    paramsObject
  )}&token=${token}`;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container style={{ marginTop: "0" }} spacing={3}>
        {dataCenter.widgetUrl && <iframe src={iframeUrl}></iframe>}
      </Grid>
    </DashboardLayout>
  );
};

QuoteExternal.propTypes = {};

QuoteExternal.defaultProps = {};

export default QuoteExternal;

import { useState, useContext } from 'react';
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import useApi from 'shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import dayjs from 'dayjs';
import { reportError } from 'shared/services/raygunService';

const useFetchPolicyRefund = () => {
  const { dataCenter } = useContext(UserDataContext);
  const [policyRefund, setPolicyRefund] = useState(null);
  const [refundDate, setRefundDate] = useState(null);
  const { setLoading } = useContext(LoaderContext);
  const [error, setError] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const API = useApi();

  const fetchRefundData = async (policy, refundDate, newPolicyCreated = false) => {
    setLoading(true);
    const formattedDate = dayjs(refundDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    setRefundDate(formattedDate);
    const policyId = policy.policy.policyNumber;
    const catalog = policy.product.iac;
    try {
      if (Object.keys(dataCenter).length > 0) {
        const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogs/${catalog}/policies/${policyId}/endorsements/refund/calculate`;
        const body = {
          type: 'POLICY',
          refundDate: formattedDate
        }
        if (newPolicyCreated) {
          body.newPolicyCreated = true;
        }
        const response = await API.post(apiUrl, body);
        const responseData = response.data;
        setPolicyRefund(`${responseData.refundAmount}`);
      }
    } catch (error) {
      setError(error);
      showNotification("COMMON.error.fetchingData", "error");
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return { policyRefund, error, fetchRefundData, setPolicyRefund, refundDate };
};

export default useFetchPolicyRefund;

import axios from "axios";
import { getToken } from "./token.helper";
import { useContext } from "react";
import { UserDataContext } from "shared/context/userDataContext";
import { AuthContext } from "../../AuthContext";

const useApi = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { platform } = useContext(AuthContext);
  const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

  const api = axios.create({
    baseURL: apiStrapiUrl,
  });

  api.interceptors.request.use(
    async (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (config.url.includes(dataCenter.cm360Endpoint)) {
        config.headers["Client-Id"] = dataCenter.psClient || "";
      }

      if (config.url.includes("/ui-proxy/")) {
        config.headers["X-Api-Key"] = dataCenter.cm360Key || "";
      }

      if (config.url.includes("catalogGroups")) {
        // @TODO: Temporary fix - move this config item to Strapi
        if (platform === "fctg") {
          config.url = config.url.replace( "/catalogGroups/", "/catalogs/");
        }
      }

      const dataCenterId = dataCenter.id;

      if (config.url.includes("agent-portal-api") && dataCenterId) {
        config.url += config.url.includes("?")
          ? `&dataCenter=${dataCenterId}`
          : `?dataCenter=${dataCenterId}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return api;
};

export default useApi;

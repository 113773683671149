import React, { useState } from 'react';
import styles from './policies.module.scss';
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { Box, Button } from '@mui/material';
import {useTranslation} from "react-i18next";
import useFetchPoliciesData from './useFetchPoliciesData';
import SuiInput from "../../../components/SoftInput";
import PoliciesTable from './policies-table.js/policies-table';
import SoftTypography from 'components/SoftTypography';
import SoftButton from "../../../components/SoftButton";

const Policies = () => {
  const {t} = useTranslation();
  const [searchPolicyId, setSearchPolicyId] = useState('');
  const { data, fetchData } = useFetchPoliciesData();

  const handleSearchButtonClick = () => {
    fetchData(searchPolicyId);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.Policies} data-testid="Policies">
        <Box className="table-toolbar" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
          <SoftTypography>{t('POLICIES.header')}</SoftTypography>
        </Box>
        <div className={`${styles['search-container']}`}>
          <SuiInput
            type="search"
            placeholder={t('POLICIES.search')}
            label={t('POLICIES.search')}
            value={searchPolicyId}
            onChange={(event) => setSearchPolicyId(event.target.value)}
            variant="outlined"
            fullWidth
            className={styles['search-input']}
            onKeyPress={handleKeyPress}
          />
          <SoftButton variant="outlined" color="info" onClick={handleSearchButtonClick}>{t('POLICIES.searchButton')}</SoftButton>
        </div>
        <PoliciesTable data={data}></PoliciesTable>
      </div>
    </DashboardLayout>
  );
};

Policies.propTypes = {};

Policies.defaultProps = {};

export default Policies;

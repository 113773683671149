import { useContext } from "react";
import { LoaderContext } from "../../shared/context/loaderContext";
import { NotificationContext } from "../../shared/context/notificationContext";
import useApi from "../../shared/const/api-backend";
import { reportError } from "shared/services/raygunService";

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

const useFetchUserDetails = () => {
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const API = useApi();

  const fetchUserDetails = async (userId) => {
    setLoading(true);

    try {
      const [rolesResponse, businessUnitsResponse, getUserResponse] = await Promise.all([
        API.get(`${apiStrapiUrl}users-permissions/roles`),
        API.get(`${apiStrapiUrl}business-units`),
        API.get(`${apiStrapiUrl}users/${userId}?populate=*`),
      ]);
      const rolesOptions = rolesResponse.data.roles
        .filter((role) => role.name !== "Public" && role.name !== "Authenticated")
        .map((r) => ({ value: r.id, label: r.name }));

      const businessUnitsOptions = businessUnitsResponse.data.map((u) => ({
        value: u.id,
        label: u.name,
      }));

      const userWithDetails = {
        ...getUserResponse.data,
        role: rolesOptions.find((r) => r.value === getUserResponse.data.role.id),
        businessUnits: businessUnitsOptions.filter((o) =>
          getUserResponse.data.businessUnits.find((u) => u.id === o.value)
        ),
      };
      return {
        roles: rolesOptions,
        businessUnits: businessUnitsOptions,
        userDetails: userWithDetails,
      };
    } catch (error) {
      showNotification("COMMON.error.fetchingData", "error");
      reportError(error);
    } finally {
      setLoading(false);
    }

    return null;
  };

  return { fetchUserDetails };
};

export default useFetchUserDetails;

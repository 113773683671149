import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { differenceInYears } from 'date-fns';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import SuiButton from '../../../../components/SoftButton';
import ValidatableSuiInput from 'shared/components/ValidatableSuiInput/ValidatableSuiInput';
import useUpdatePolicyDetails from '../useUpdatePolicyDetails';
import useFetchMedicalScreening from '../useFetchMedicalScreening';
import { allowedChars } from 'shared/const/regexps';
import { MAX_LENGTH } from './travelersTab.consts';
import './travelersTab.scss';
import { NotificationContext } from 'shared/context/notificationContext';
import dayjs from 'dayjs';
import { reportError } from 'shared/services/raygunService';

const TravelersTab = ({ policyDetails, onPolicyDetailsUpdate }) => {
  const { t } = useTranslation();
  const { updatePolicyDetails } = useUpdatePolicyDetails();
  const { fetchMedicalScreeningData } = useFetchMedicalScreening();
  const [state, setState] = useState({
    editing: false,
    editedValues: [],
    previewOpen: false,
    conditionsData: null,
    medicalPreview: {},
    inputErrors: [],
  });
  const { showNotification } = useContext(NotificationContext);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      editedValues: [...policyDetails.beneficiaries],
    }));
  }, [policyDetails]);

  const handleInputChange = (index, key, value) => {
    let firstNameError = state.inputErrors[index]?.firstName;
    let lastNameError = state.inputErrors[index]?.lastName;

    if (key === 'firstName') {
      firstNameError = null;

      if (!value || value.length === 0) {
        firstNameError = t('COMMON.error.requiredField');
      } else if (allowedChars.test(value)) {
        firstNameError = t('COMMON.error.invalidValue');
      } else if (value.length > MAX_LENGTH) {
        firstNameError = t('COMMON.error.maxLengthExceeded');
      }
    } else if (key === 'lastName') {
      lastNameError = null;

      if (!value || value.length === 0) {
        lastNameError = t('COMMON.error.requiredField');
      } else if (allowedChars.test(value)) {
        lastNameError = t('COMMON.error.invalidValue');
      } else if (value.length > MAX_LENGTH) {
        lastNameError = t('COMMON.error.maxLengthExceeded');
      }
    }

    const updatedErrors = [...state.inputErrors];
    updatedErrors[index] = {
      ...state.inputErrors[index],
      firstName: firstNameError,
      lastName: lastNameError,
    };

    const updatedValues = [...state.editedValues];
    updatedValues[index] = {
      ...updatedValues[index],
      [key]: value,
    };

    setState((prevState) => ({
      ...prevState,
      editedValues: updatedValues,
      inputErrors: updatedErrors,
    }));
  };

  const handleSaveClick = async () => {
    if (!policyDetails || validateFields()) {
      return;
    }

    const updatedPolicyDetails = {
      ...policyDetails,
      beneficiaries: state.editedValues,
    };

    try {
      await updatePolicyDetails(policyDetails.policy.policyNumber, updatedPolicyDetails);
      onPolicyDetailsUpdate();
    } catch (error) {
      reportError(error);
    }

    setState((prevState) => ({
      ...prevState,
      editedValues: state.editedValues,
      editing: false,
    }));
  };

  const handleCancelClick = () => {
    setState((prevState) => ({
      ...prevState,
      editing: false,
      editedValues: [...policyDetails.beneficiaries],
      inputErrors: [],
    }));
  };

  const handleEditClick = () => {
    if (policyDetails && !dayjs(policyDetails.insuranceData.endDate).add(6, 'month').isAfter(dayjs(), 'day')) {
      showNotification(t("POLICY_DETAILS.editingAfterEndNotification"), 'error');
      return;
    }
    if (policyDetails && policyDetails.policy.status !== 'cancelled') {
      setState((prevState) => ({
        ...prevState,
        editing: true,
      }));
    }
  };

  const handlePreviewMedicalConditions = async (companionId) => {
    if (state.medicalPreview[companionId]) {
      setState((prevState) => ({...prevState, conditionsData: state.medicalPreview[companionId], previewOpen: true}));
    } else {
      try {
        const medicalData = await fetchMedicalScreeningData(companionId);
        setState((prevState) => ({...prevState, conditionsData: medicalData, medicalPreview: { ...state.medicalPreview, [companionId]: medicalData }, previewOpen: true}));
      } catch (error) {
        reportError(error);
      }
    }
  };

  const handleClosePreview = () => {
    setState((prevState) => ({
      ...prevState,
      previewOpen: false,
    }));
  };

  const validateFields = () => {
    const validateBeneficiary = (beneficiary) => {
      const isFirstNameValid = !beneficiary.firstName || beneficiary.firstName.trim().length === 0
        ? t('COMMON.error.requiredField')
        : beneficiary.firstName.trim().length > MAX_LENGTH
        ? t('COMMON.error.maxLengthExceeded')
        : allowedChars.test(beneficiary.firstName)
        ? t('COMMON.error.invalidValue')
        : null;

      const isLastNameValid = !beneficiary.lastName || beneficiary.lastName.trim().length === 0
        ? t('COMMON.error.requiredField')
        : beneficiary.lastName.trim().length > MAX_LENGTH
        ? t('COMMON.error.maxLengthExceeded')
        : allowedChars.test(beneficiary.lastName)
        ? t('COMMON.error.invalidValue')
        : null;

      return { firstName: isFirstNameValid, lastName: isLastNameValid };
    };

    const updatedErrors = state.editedValues.map(validateBeneficiary);
    setState((prevState) => ({ ...prevState, inputErrors: updatedErrors }));

    return updatedErrors.some((errors) => Object.values(errors || {}).some((error) => error !== null));
  };

  const calculateAge = (birthdate, subscriptionDate) => {
    if (!birthdate || !subscriptionDate) {
      return '-';
    }
    return differenceInYears(new Date(subscriptionDate), new Date(birthdate));
  };

  const renderInputField = (label, value, key, index) => (
    <div className="field-item">
      <strong>{label}:</strong>
      <ValidatableSuiInput
        fullWidth
        value={value}
        required
        inputProps={{ maxLength: MAX_LENGTH }}
        onChange={(e) => handleInputChange(index, key, e.target.value)}
        errorMessage={state.inputErrors[index]?.[key]}
        error={!!state.inputErrors[index]?.[key]}
      />
    </div>
  );

  const renderTextField = (label, value) => (
    <div className="field-item">
      <strong>{label}:</strong> {value}
    </div>
  );

  return (
    <div className="travelersTab">
      <div className="header">
        <h4>{t('POLICY_DETAILS.tabTravelers')}</h4>
        <div>
          {policyDetails &&
          policyDetails.policy.status.label.toLowerCase() !== 'cancelled' &&
          policyDetails.beneficiaries.some((beneficiary) => !beneficiary.subscriber) ? (
            state.editing ? (
              <>
                <SuiButton
                  size="small"
                  variant="gradient"
                  color="success"
                  className="button"
                  onClick={handleSaveClick}
                >
                  {t('COMMON.save')}
                </SuiButton>
                <SuiButton
                  size="small"
                  variant="outlined"
                  color="info"
                  className="button ml"
                  onClick={handleCancelClick}
                >
                  {t('COMMON.cancel')}
                </SuiButton>
              </>
            ) : (
              <SuiButton
                size="small"
                variant="gradient"
                color="info"
                className="button"
                onClick={handleEditClick}
              >
                {t('COMMON.edit')}
              </SuiButton>
            )
          ) : null}
        </div>
      </div>

      <div className="field-container">
        <Grid container>
          {state.editedValues.map((beneficiary, index) => (
            <Grid item sm={12} md={6} lg={4} key={index}>
              <div className={`field ${state.editing ? 'editable' : ''}`}>
                <div className="field-label">
                  <strong>{t('Traveler')} {index + 1}</strong>
                </div>
                {state.editing && !beneficiary.subscriber ? (
                  <>
                    {renderInputField(t('POLICY_DETAILS.TRAVELERS.firstName'), beneficiary.firstName, 'firstName', index)}
                    {renderInputField(t('POLICY_DETAILS.TRAVELERS.lastName'), beneficiary.lastName, 'lastName', index)}
                    {renderTextField(t('POLICY_DETAILS.TRAVELERS.age'), beneficiary.birthDate ?
                      calculateAge(beneficiary.birthDate, policyDetails.policy.subscriptionDate) : beneficiary.value8)}
                    {renderTextField(t('POLICY_DETAILS.TRAVELERS.medicalInfo'), beneficiary?.medicalInfoExists ? t('COMMON.yes') : t('COMMON.no'))}
                  </>
                ) : (
                  <>
                    {renderTextField(t('POLICY_DETAILS.TRAVELERS.firstName'), beneficiary.firstName)}
                    {renderTextField(t('POLICY_DETAILS.TRAVELERS.lastName'), beneficiary.lastName)}
                    {renderTextField(t('POLICY_DETAILS.TRAVELERS.age'), beneficiary.birthDate ?
                      calculateAge(beneficiary.birthDate, policyDetails.policy.subscriptionDate) : beneficiary.value8)}
                    {renderTextField(t('POLICY_DETAILS.TRAVELERS.medicalInfo'), beneficiary?.medicalInfoExists ? t('COMMON.yes') : t('COMMON.no'))}
                  </>
                )}
                <div className="field-item">
                  {beneficiary?.medicalInfoExists && (
                    <SuiButton
                      size="small"
                      variant="outlined"
                      color="primary"
                      className="previewButton"
                      onClick={() => handlePreviewMedicalConditions(beneficiary.companionId)}
                    >
                      {t('POLICY_DETAILS.TRAVELERS.previewMedicalConditions')}
                    </SuiButton>
                  )}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>

      <Dialog open={state.previewOpen} onClose={handleClosePreview}
              PaperProps={{ style: { minWidth: '30rem' } }}
      >
        <DialogTitle>{t('POLICY_DETAILS.TRAVELERS.MEDICAL_CONDITIONS.medicalConditions')}</DialogTitle>
        <DialogContent>
          { state.conditionsData?.conditions && state.conditionsData?.conditions.length > 0 ?
            state.conditionsData.conditions.map((condition, index) => (
              <div key={index} className="beneficiariesTab-medicalConditions">
                <h2>{condition.name}</h2>
                {
                  condition.questions.map((question, qIndex) => (
                    <div key={qIndex}>
                      <p><b>{t('POLICY_DETAILS.TRAVELERS.MEDICAL_CONDITIONS.question')}:</b> {question.question}</p>
                      <p><b>{t('POLICY_DETAILS.TRAVELERS.MEDICAL_CONDITIONS.answer')}:</b> {question.answer}</p>
                    </div>
                  ))
                }
              </div>
            ))
            :
            <div className="beneficiariesTab-noMedicalConditions">
              {t('POLICY_DETAILS.TRAVELERS.MEDICAL_CONDITIONS.noMedicalConditions')}
            </div>
          }
        </DialogContent>
        <DialogActions>
          <SuiButton onClick={handleClosePreview} variant="outlined" color="secondary">
            {t('COMMON.close')}
          </SuiButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TravelersTab.propTypes = {
  policyDetails: PropTypes.shape({
    policy: PropTypes.shape({
      policyNumber: PropTypes.string.isRequired,
      status: PropTypes.object.isRequired,
      subscriptionDate: PropTypes.string.isRequired,
    }),
    beneficiaries: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        birthDate: PropTypes.string,
        countryCode: PropTypes.string,
        index: PropTypes.string,
        companionId: PropTypes.number,
      })
    ),
    insuranceData: PropTypes.shape({
      marketingOffers: PropTypes.bool,
      startDate: PropTypes.date,
      endDate: PropTypes.date,
    }),
  }).isRequired,
  onPolicyDetailsUpdate: PropTypes.func.isRequired,
};

export default TravelersTab;

import { useState, useContext } from 'react';
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import useApi from '../../../shared/const/api-backend';
import { UserDataContext } from "../../../shared/context/userDataContext";
import { reportError } from 'shared/services/raygunService';

const useOptOutOfMarketingConsents = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const API = useApi();

  const optOutOfMarketingConsents = async (bookingId) => {
    setLoading(true);

    const payload = [
      {
        "type": "MARKETING_PORTAL_PROMO_ACCEPTANCE_OPT_OUT",
        "isAgentExclusive": true,
        "version": 1
      }
    ]

    try {
      const apiUrl = `${dataCenter.cm360Endpoint}/booking/${bookingId}/consents`;
      const headers = {
        'X-Api-Key': dataCenter.cm360Key
      };
      await API.post(apiUrl, payload, { headers });
    } catch (error) {
      setError(error);
      showNotification("Error updating policy details", "error");
      reportError(error);
    } finally {
      showNotification("Policy has been updated", "success");
      setLoading(false);
    }
  };

  return { optOutOfMarketingConsents, error };
};

export default useOptOutOfMarketingConsents;

import { useState, useContext } from 'react';
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import { format } from 'date-fns';
import useApi from '../../../shared/const/api-backend';
import { UserDataContext } from "../../../shared/context/userDataContext";
import { reportError } from 'shared/services/raygunService';

const useUpdatePolicyDetails = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const API = useApi();

  const updatePolicyDetails = async (policyId, updatedPolicyDetails) => {
    setLoading(true);

    try {
      const { beneficiaries, insuranceData, policy, product, subscriber } = updatedPolicyDetails;
      const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogs/${product.iac}/policies/${policyId}`;

      const payload = {
        beneficiaries: beneficiaries.map(({ index, genericData, wasScreened, ...beneficiary }) => ({
          ...beneficiary, birthDate: format(new Date(beneficiary.birthDate), 'yyyy-MM-dd')
        })),
        insurance: {
          bookingDate: insuranceData.bookingDate,
          startDate: insuranceData.startDate,
          endDate: insuranceData.endDate,
          insuranceData: { ...policy.genericData }
        },
        product: {
          productGroup: [{ code: product.productCode }]
        },
        subscriber: {
          birthdate: format(new Date(subscriber.birthDate), 'yyyy-MM-dd'), // birthDate != birthdate
          civility: subscriber.civility,
          firstName: subscriber.firstName,
          lastName: subscriber.lastName,
          email: subscriber.email,
          phone: subscriber.phone,
          mobilePhone: subscriber.mobilePhone,
          address1: subscriber.address1,
          address2: subscriber.address2,
          postalCode: subscriber.postalCode,
          city: subscriber.city,
          countryCode: subscriber.country,
          preferredLanguage: subscriber.preferredLanguage
        },
        subscription: {
          price: policy.price.grossPremium,
          currency: policy.price.currency
        }
      };

      // delete payload.subscriber.country;
      // delete payload.subscriber.birthDate; // birthDate != birthdate
      // delete payload.subscriber.value4; // todo: has to be fixed in backend

      await API.put(apiUrl, payload);

    } catch (error) {
      setError(error);
      showNotification("Error updating policy details", "error");
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return { updatePolicyDetails, error };
};

export default useUpdatePolicyDetails;

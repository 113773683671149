import React, { useContext, useState, useEffect } from 'react';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import Grid from '@mui/material/Grid';
import QuoteCard from './quote-card/quote-card';
import { UserDataContext } from 'shared/context/userDataContext';
import PropTypes from 'prop-types';
import './new-quote.scss';

const NewQuote = () => {
    const { dataCenter, userData } = useContext(UserDataContext);
    const [shortcuts, setShortcuts] = useState([]);

    useEffect(() => {
        setShortcuts(userData.brands);
    }, [dataCenter]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>
                {shortcuts?.map((item, index) => (
                    <Grid key={index} item lg={4}>
                        <QuoteCard item={item} />
                    </Grid>
                ))}
            </Grid>
        </DashboardLayout>
    );
}

NewQuote.propTypes = {};
NewQuote.defaultProps = {};

export default NewQuote;

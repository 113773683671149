import React, { useState } from 'react';
import { IconButton, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useFetchCustomersData from './useFetchCustomersData';
import SuiInput from '../../../components/SoftInput';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import GenericTable from 'shared/components/GenericTable/genericTable';
import DashboardLayout from '../../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../../examples/Navbars/DashboardNavbar';

import styles from './customers.module.scss';
import {format} from "date-fns";
import {CONFIG_APP} from "../../../shared/const/app.config";

const Customers = () => {
  const { t } = useTranslation();
  const [searchFirstName, setSearchFirstName] = useState('');
  const [searchLastName, setSearchLastName] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searching, setSearching] = useState(false);
  const { data, fetchData } = useFetchCustomersData();
  const navigate = useNavigate();

  const handleSearchButtonClick = async () => {
    setSearching(true);
    await fetchData(searchFirstName, searchLastName, searchEmail);
    setSearching(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchButtonClick();
    }
  };

  const openDetails = (customer) => {
    const url = `/customer-details/${customer.id}/${customer.countryCode}`;
    navigate(url);
  };

  const columns = [
    {
      label: t('CUSTOMERS.title'),
      value: (customer) => customer.title,
    },
    {
      label: t("CUSTOMERS.firstName"),
      value: (customer) => customer.firstName,
    },
    {
      label: t("CUSTOMERS.lastName"),
      value: (customer) => customer.lastName,
    },
    {
      label: t('CUSTOMERS.birthdate'),
      value: (customer) => format(new Date(customer.dateOfBirth), CONFIG_APP.BIRTH_DATE_FORMAT),
    },
    {
      label: t('CUSTOMERS.email'),
      value: (customer) => customer.emailAddress,
    },
    {
      label: t('CUSTOMERS.phone'),
      value: (customer) => customer.phoneNumber1,
    },
    {
      label: t('CUSTOMERS.country'),
      value: (customer) => customer.countryCode,
    },
    {
      label: t('CUSTOMERS.actions'),
      value: () => (
        <IconButton size="small" color="info">
          <PlayCircleIcon />
        </IconButton>
      ),
      width: '70px',
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.Customers} data-testid="Customers">
        <Box className="table-toolbar" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
          <SoftTypography>{t('CUSTOMERS.header')}</SoftTypography>
        </Box>
        <div className={styles.searchContainer}>
          <div className={styles.searchInputs}>
            <SuiInput
              type="search"
              placeholder={t('CUSTOMERS.searchFirstName')}
              label={t('CUSTOMERS.searchFirstName')}
              value={searchFirstName}
              onChange={(e) => setSearchFirstName(e.target.value)}
              variant="outlined"
              fullWidth
              className={styles['search-input']}
              onKeyPress={handleKeyPress}
            />
            <SuiInput
              type="search"
              placeholder={t('CUSTOMERS.searchLastName')}
              label={t('CUSTOMERS.searchLastName')}
              value={searchLastName}
              onChange={(e) => setSearchLastName(e.target.value)}
              variant="outlined"
              fullWidth
              className={styles['search-input']}
              onKeyPress={handleKeyPress}
            />
            <SuiInput
              type="search"
              placeholder={t('CUSTOMERS.searchEmail')}
              label={t('CUSTOMERS.searchEmail')}
              value={searchEmail}
              onChange={(e) => setSearchEmail(e.target.value)}
              variant="outlined"
              fullWidth
              className={styles['search-input']}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className={styles.searchButton}>
            <SoftButton variant="outlined" color="info" onClick={handleSearchButtonClick} disabled={searching}>
              {t('CUSTOMERS.search')}
            </SoftButton>
          </div>
        </div>

        <GenericTable data={data} columns={columns} path="CUSTOMERS" onRowClick={openDetails} />
      </div>
    </DashboardLayout>
  );
};

export default Customers;

import React, { createContext, useMemo, useState } from 'react';
import PropTypes from "prop-types";

const contextDefaultValues = {
    appConfiguration: null,
    updateConfiguration: (appConfiguration) => undefined,
};

export const AppConfigurationContext =
    createContext(contextDefaultValues);

export const AppConfigurationContextProvider = ({ children }) => {
    const [appConfiguration, setAppConfig] = useState(
        contextDefaultValues.appConfiguration,
    );

    const handleUpdate = (appConfigurationData) => {
        setAppConfig(appConfigurationData);
    };

    const appConfig = useMemo(
        () => ({ appConfiguration, updateConfiguration: handleUpdate }),
        [appConfiguration],
    );

    return (
        <AppConfigurationContext.Provider value={appConfig}>
            {children}
        </AppConfigurationContext.Provider>
    );
};

AppConfigurationContextProvider.propTypes = {
    children: PropTypes.node,
};

import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import SoftButton from "components/SoftButton";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const DialogConfirmation = ({open, onClose, onAction, actionColor, actionLabel, path }) => {
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t(`${path}.title`)}</DialogTitle>
            <DialogContent>
                <p>{t(`${path}.message`)}</p>
            </DialogContent>
            <DialogActions>
                {onAction && (
                <SoftButton size='small' color={actionColor} onClick={onAction}>
                    <CheckIcon sx={{marginRight: '0.2rem'}} />
                    {t(`${path}.${actionLabel}`)}
                </SoftButton>
                )}
                <SoftButton size='small' onClick={onClose}>
                    <CloseIcon sx={{marginRight: '0.2rem'}} />
                    {t('COMMON.close')}
                </SoftButton>
            </DialogActions>
        </Dialog>
    );
};

DialogConfirmation.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onAction: PropTypes.func,
    path: PropTypes.string.isRequired,
    actionLabel: PropTypes.string,
    actionColor: PropTypes.string,
};

export default DialogConfirmation;

import { useContext } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import useApi from '../../../../shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import { reportError } from 'shared/services/raygunService';

const useFetchAllValuablesEndorsementsDetails = () => {
  const { showNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const { dataCenter } = useContext(UserDataContext);

  const API = useApi();

  const fetchValuableEndorsementDetails = async (catalogId, id) => {
    const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogs/${catalogId}/endorsements/${id}`;
    const headers = {
      'X-Api-Key': dataCenter.cm360Key || '',
    };
    const response = await API.get(apiUrl, { headers });
    if (response.status !== 200) {
      throw new Error('Network response was not ok');
    }
    const responseData = response.data;
    return responseData;
  }

  const fetchAllValuablesEndorsementsDetails = async (endorsements, catalogId) => {
    setLoading(true);
    const promises = [];
    endorsements.forEach((e) => {
      promises.push(fetchValuableEndorsementDetails(catalogId, e.endorsementNumber))
    })
    return Promise.all(promises).then((result) => {
      return result;
    }).catch((error) => {
      showNotification('Error fetching endorsements', 'error');
      reportError(error);
    }).finally(() => {
      setLoading(false);

    });
  };
  return { fetchAllValuablesEndorsementsDetails };
};

export default useFetchAllValuablesEndorsementsDetails;

import { useEffect, useState, useContext } from 'react';
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import useApi from '../../../shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import { reportError } from 'shared/services/raygunService';

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

const useFetchCustomerDetails = (customerId, country) => {
  const [customerDetails, setCustomerDetails] = useState(null);
  const { setLoading } = useContext(LoaderContext);
  const [error, setError] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const API = useApi();
  const { dataCenter } = useContext(UserDataContext);

  const fetchData = async () => {
    setLoading(true);
    try {
      if (Object.keys(dataCenter).length > 0) {
        const response = await API.get(`${apiStrapiUrl}customers/${customerId}?countries=${country}`);
        setCustomerDetails(response.data.data);
      }
    } catch (error) {
      setError(error);
      showNotification("CUSTOMERS.error.fetchingData", "error");
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [customerId, country, dataCenter]);

  return { customerDetails, error, fetchData };
};

export default useFetchCustomerDetails;

export const getLocaleByCurrency = (currency) => {
        switch (currency) {
                case 'AUD':
                        return 'en-AU';
                case 'USD':
                        return 'en-US';
                default:
                        return 'en-AU';
        }

}

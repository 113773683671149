import React from 'react';
import PropTypes from 'prop-types';
import styles from '../customerDetails.module.scss';
import PendingQuotesTable from 'layouts/home/pending-quotes/pending-quotes-table/pending-quotes-table';

const PendingQuotes = ({ pendingQuotes }) => {
  return (
    <div>
      <div className={styles.PendingQuotes} data-testid="PendingQuotes">
        <PendingQuotesTable data={pendingQuotes} />
      </div>
    </div>
  );
};

PendingQuotes.propTypes = {
  pendingQuotes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PendingQuotes;

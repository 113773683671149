import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../../../../shared/context/notificationContext";
import useApi from "shared/const/api-backend";
import { UserDataContext } from "shared/context/userDataContext";
import { reportError } from "shared/services/raygunService";

const useFetchEndorsements = (policyId) => {
  const { dataCenter } = useContext(UserDataContext);
  const [endorsements, setEndorsements] = useState(null);
  const [error, setError] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const API = useApi();

  const fetchEndorsementsData = async () => {
    try {
      if (Object.keys(dataCenter).length > 0) {
        const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogGroups/${dataCenter.catalogGroup}/policies/${policyId}/endorsements`;
        const response = await API.get(apiUrl);
        const responseData = response.data;
        setEndorsements(responseData);
      }
    } catch (error) {
      if (error.response.status === 404) {
        showNotification("COMMON.error.policyNotFound", "error");
      } else {
        showNotification("COMMON.error.fetchingData", "error");
        setError(error);
      }
      reportError(error);
    }
  };

  useEffect(() => {
    fetchEndorsementsData();
  }, [policyId, dataCenter]);

  return { endorsements, error, fetchEndorsementsData };
};

export default useFetchEndorsements;

import React from 'react';
import PropTypes from "prop-types";

export const LoaderContext = React.createContext();

export const LoaderProvider = ({ children }) => {
    const [loading, setLoading] = React.useState(false);

    return (
        <LoaderContext.Provider value={{ loading, setLoading }}>
            {children}
        </LoaderContext.Provider>
    );
};

LoaderProvider.propTypes = {
    children: PropTypes.node,
};

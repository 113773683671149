import React, { useContext, useEffect, useState } from 'react';
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { Box, Card, CardContent } from '@mui/material';
import { UserDataContext } from 'shared/context/userDataContext';
import { useTranslation } from 'react-i18next';
import SoftTypography from 'components/SoftTypography';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import useApi from 'shared/const/api-backend';
import { reportError } from 'shared/services/raygunService';

const DiscountApproval = () => {
  const { t } = useTranslation();
  const { dataCenter } = useContext(UserDataContext);
  const [discountStatus, setDiscountStatus] = useState('');
  const { setLoading, loading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const API = useApi();

  useEffect(() => {
    if (!dataCenter?.cm360Endpoint) {
      return;
    }
    const approveDiscount = async () => {
      try {
        setLoading(true);
        const params = new URLSearchParams(window.location.search);
        const bookingId = params.get('bookingDataId');
        const apiUrl = `${dataCenter.cm360Endpoint}/booking/${bookingId}/discount/approve`;
        const headers = {
          'X-Api-Key': dataCenter.cm360Key || '',
        };
        const response = await API.post(apiUrl, {}, { headers });
        if (response.status !== 201) {
          showNotification('Error fetching discount', 'error');
        }

        const responseData = response.data;
        setDiscountStatus(responseData.msg);
      } catch (error) {
        showNotification('Error fetching discount', 'error');
        reportError(error);
      } finally {
        setLoading(false);
      }
    }
    approveDiscount();
  }, [dataCenter]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="discount-approval" data-testid="DiscountApproval">
        <Box className="table-toolbar" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
          <SoftTypography>{t('DISCOUNT_APPROVAL.header')}</SoftTypography>
        </Box>
        {
          !loading && discountStatus &&
          <Box>
            <Card sx={{ width: '400px', margin: '0 auto' }}>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <SoftTypography sx={{ mt: 2 }}>{t('Discount status:')}</SoftTypography>
                  {<>
                    <SoftTypography sx={{ mb: 1, fontWeight: 'bold', textAlign: 'center' }}>{discountStatus}</SoftTypography>
                  </>
                  }
                </Box>
              </CardContent>
            </Card>
          </Box>
        }
      </div>
    </DashboardLayout>
  );
};

DiscountApproval.propTypes = {};

DiscountApproval.defaultProps = {};

export default DiscountApproval;

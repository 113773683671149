import { useState, useContext, useCallback } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import useApi from '../../../../shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import { reportError } from 'shared/services/raygunService';

const useFetchEndorsementDetails = () => {
  const [error, setError] = useState(null);
  const [endorsementDetails, setEndorsementDetails] = useState(null);
  const [endorsementTripDurationDetails, setEndorsementTripDurationDetails] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const { dataCenter } = useContext(UserDataContext);

  const API = useApi();

  const fetchEndorsementDetails = useCallback(async (id, catalogId, onlyTripDuration) => {
    try {
      setLoading(true);
      const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogs/${catalogId}/endorsements/${id}`;
      const headers = {
        'X-Api-Key': dataCenter.cm360Key || '',
      };
      const response = await API.get(apiUrl, { headers });
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }
      const responseData = response.data;
      if (onlyTripDuration) {
        setEndorsementTripDurationDetails(responseData);
        return responseData;
      }
      setEndorsementDetails(responseData);
      return responseData;
    } catch (error) {
      setError(error);
      showNotification('Error fetching endorsements', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  }, [showNotification, setLoading]);

  return { endorsementDetails, endorsementTripDurationDetails, error, fetchEndorsementDetails };
};

export default useFetchEndorsementDetails;

import React, { useContext } from 'react';
import { AuthContext } from 'AuthContext';
import DashboardWithWidgets from '../dashboard-with-widgets/dashboard-with-widgets';
import DashboardEmpty from '../dashboard-empty/dashboard-empty';

const Dashboard = () => {
  const { platform } = useContext(AuthContext);
  return platform === 'fctg' ?
    <DashboardWithWidgets /> :
    <DashboardEmpty />
};

Dashboard.propTypes = {};
Dashboard.defaultProps = {};

export default Dashboard;

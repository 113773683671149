import { useState, useContext } from 'react';
import { LoaderContext } from "shared/context/loaderContext";
import { NotificationContext } from "shared/context/notificationContext";
import useApi from 'shared/const/api-backend';
import { UserDataContext } from "shared/context/userDataContext";
import { reportError } from 'shared/services/raygunService';

const useCancelEndorsement = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [error, setError] = useState(null);
  const API = useApi();

  const cancelEndorsement = async (policy, id, refundAmount) => {
    setLoading(true);
    try {
      const apiUrl = `${dataCenter.cm360Endpoint}/ui-proxy/ws-partners/api/platforms/${dataCenter.psPlatform}/catalogs/${policy.product.iac}/endorsements/${id}`;
      await API.post(apiUrl, {refundAmount});
    } catch (error) {
      setError(error);
      showNotification("Error cancelling endorsement", "error");
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  return { cancelEndorsement, error };
};

export default useCancelEndorsement;

import React, { useContext } from 'react';
import styles from './my-profile.module.scss';
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { UserDataContext } from 'shared/context/userDataContext';
import { useTranslation } from 'react-i18next';
import SoftTypography from 'components/SoftTypography';

const MyProfile = () => {
  const { t } = useTranslation();
  const joinWithCommas = (items) => (items ? items.join(', ') : '');
  const { userData } = useContext(UserDataContext);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className={styles.MyProfile} data-testid="MyProfile">
        <Box className="table-toolbar" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2 }}>
          <SoftTypography>{t('My profile')}</SoftTypography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent className={styles.cardContent}>
                {userData && (
                  <>
                    <SoftTypography>{t('MY_PROFILE.userdata')}</SoftTypography>
                    <Typography variant="body2">
                      {t('MY_PROFILE.name')}: {userData.firstName} {userData.lastName}
                    </Typography>
                    <Typography variant="body2">
                      {t('MY_PROFILE.email')}: {userData.email}
                    </Typography>
                    <Typography variant="body2">
                      {t('MY_PROFILE.role')}: {userData.role?.name}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent className={styles.cardContent}>
                {userData && (
                  <>
                    <SoftTypography>{t('MY_PROFILE.dataCenters')}</SoftTypography>
                    <Typography variant="body2">
                      {t('MY_PROFILE.brands')}: {joinWithCommas(userData.brands?.map(brand => brand.brand))}
                    </Typography>
                    <Typography variant="body2">
                      {t('MY_PROFILE.businessUnits')}: {joinWithCommas(userData.businessUnits?.map(unit => unit.name))}
                    </Typography>
                    <Typography variant="body2">
                      {t('MY_PROFILE.dataCenters')}: {joinWithCommas(userData.dataCenters?.map(center => center.code))}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};

MyProfile.propTypes = {};

MyProfile.defaultProps = {};

export default MyProfile;

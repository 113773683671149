import { useContext, useState } from "react";
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import useApi from "../../../shared/const/api-backend";
import { UserDataContext } from "shared/context/userDataContext";
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";

const useFetchDashboardWidgetsData = () => {
  const { t } = useTranslation();
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [data, setData] = useState(null);
  const API = useApi();

  const fetchData = async (dateRange) => {
    setLoading(true);
    try {
      const startDate = dayjs(dateRange[0]).unix();
      const endDate = dayjs(dateRange[1]).unix();
      const url = `${dataCenter.cm360Endpoint}/dashboard?startDate=${startDate}&endDate=${endDate}`;
      const response = await API.get(url, {
        headers: {
          'X-Api-Key': dataCenter.cm360Key
        }
      });
      if (response.status !== 200) {
        throw new Error(t("COMMON.error.responseError"));
      }
      setData(response.data);
    } catch (error) {
      showNotification("COMMON.error.fetchingData", "error");
    } finally {
      setLoading(false);
    }
  };

  return { data, fetchData };
};

export default useFetchDashboardWidgetsData;

export const getLoginRouteByPlatform = (platform) => {
  console.log("platform", platform);
  switch (platform) {
    case "puzzle":
      return "/ea-sign-in";
    case "fctg":
      return "/fctg-sign-in";
    default:
      return "/login";
  }
};

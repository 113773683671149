import { useContext, useCallback } from 'react';
import { NotificationContext } from 'shared/context/notificationContext';
import { LoaderContext } from 'shared/context/loaderContext';
import useApi from 'shared/const/api-backend';
import {UserDataContext} from "../../../shared/context/userDataContext";
import { reportError } from 'shared/services/raygunService';

const useSendMedicalCertificate = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { showNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const API = useApi();

  const sendMedicalCertificate = useCallback(async (bookingId) => {
    try {
      setLoading(true);
      const apiUrl = `${dataCenter.cm360Endpoint}/booking/${bookingId}/certificate`;
      const headers = {
        'X-Api-Key': dataCenter.cm360Key
      };
      await API.post(apiUrl, null, { headers });
      setLoading(false);
      return { success: true };
    } catch (error) {
      setLoading(false);
      showNotification('Error sending medical certificate', 'error');
      reportError(error);
      return { success: false, error: error.message };
    }
  }, [showNotification]);

  return sendMedicalCertificate;
};

export default useSendMedicalCertificate;

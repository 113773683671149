import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'AuthContext';
import { getToken } from 'shared/const/token.helper';

const GetQuote = () => {
    const { platform } = useContext(AuthContext);
    const navigate = useNavigate();
    const token = getToken();
    useEffect(() => {
        const redirectUrl = `/quote?brand=${platform}&agent=true&token=${token}`
        navigate(redirectUrl);
    }, []);
}

GetQuote.propTypes = {};
GetQuote.defaultProps = {};

export default GetQuote;

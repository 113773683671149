import React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import "./welcomeTravelAlerts.scss";
import { useMediaQuery } from "@mui/material";
import theme from "../../../../assets/theme";
import SoftTypography from "../../../../components/SoftTypography";

const WelcomeTravelAlerts = ({ data = '' }) => {
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <Paper
      className={`${matches ? "travel-alerts-desktop" : "travel-alerts-mobile"} travel-alerts`}
    >
      <SoftTypography
        component="span"
        variant="body2"
        color="dark"
        fontWeight="medium"
        className="travel-alerts-text"
      >
        {data}
      </SoftTypography>
    </Paper>
  );
};

WelcomeTravelAlerts.propTypes = {
  data: PropTypes.string,
};

export default WelcomeTravelAlerts;



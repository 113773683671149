import { useState, useContext, useCallback } from 'react';
import { LoaderContext } from 'shared/context/loaderContext';
import { NotificationContext } from 'shared/context/notificationContext';
import useApi from '../../../shared/const/api-backend';
import { UserDataContext } from 'shared/context/userDataContext';
import { reportError } from 'shared/services/raygunService';

const useFetchAuditHistory = () => {
  const [error, setError] = useState(null);
  const [auditHistory, setAuditHistory] = useState(null);
  const { showNotification } = useContext(NotificationContext);
  const { setLoading } = useContext(LoaderContext);
  const { dataCenter } = useContext(UserDataContext);

  const API = useApi();

  const fetchAuditHistory = useCallback(async (bookingId) => {
    try {
      setLoading(true);
      const apiUrl = `${dataCenter.cm360Endpoint}/audit_log/?bookingId=${bookingId}`;
      const headers = {
        'X-Api-Key': dataCenter.cm360Key || '',
      };
      const response = await API.get(apiUrl, {headers});
      if (response.status !== 200) {
        throw new Error('Network response was not ok');
      }

      const responseData = response.data.reverse();
      setAuditHistory(responseData);
      return responseData;
    } catch (error) {
      setError(error);
      showNotification('Error fetching certificate', 'error');
      reportError(error);
    } finally {
      setLoading(false);
    }
  }, [showNotification, setLoading]);

  return { auditHistory, error, fetchAuditHistory };
};

export default useFetchAuditHistory;

import React, { useState } from "react";
import styles from './pending-quotes.module.scss';
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import SoftTypography from "../../../components/SoftTypography";
import { Button, Box } from '@mui/material';
import { useTranslation } from "react-i18next";
import PendingQuotesTable from "./pending-quotes-table/pending-quotes-table";
import useFetchPendingQuotes from "./useFetchPendingQuotes";
import SuiInput from "../../../components/SoftInput";
import SoftButton from "../../../components/SoftButton";

const PendingQuotes = () => {
    const {t} = useTranslation();
    const [searchQuoteId, setSearchQuoteId] = useState('');
    const { data, error, fetchData } = useFetchPendingQuotes(searchQuoteId);

    const handleSearchButtonClick = () => {
        fetchData(searchQuoteId);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearchButtonClick();
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <div className={styles.PendingQuotes} data-testid="PendingQuotes">
                <Box className="table-toolbar" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', py: 2}}>
                    <SoftTypography>{t('HOME.PENDING_QUOTES.table.label')}</SoftTypography>
                </Box>
                <div className={`${styles['search-container']}`}>
                    <SuiInput
                        type="search"
                        placeholder={t('HOME.PENDING_QUOTES.search')}
                        label={t('HOME.PENDING_QUOTES.search')}
                        value={searchQuoteId}
                        onChange={(event) => setSearchQuoteId(event.target.value.slice(0,20))}
                        variant="outlined"
                        fullWidth
                        className={styles['search-input']}
                        onKeyPress={handleKeyPress}
                    />
                    <SoftButton variant="outlined" color="info" onClick={handleSearchButtonClick}>
                        {t('HOME.PENDING_QUOTES.searchButton')}
                    </SoftButton>
                </div>
            </div>
            <PendingQuotesTable data={data}/>
        </DashboardLayout>
    );
}

PendingQuotes.propTypes = {};

PendingQuotes.defaultProps = {};

export default PendingQuotes;

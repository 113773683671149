import { useContext } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import authFctgBackground from "assets/images/background_login_fctg.png";
import AuthLayout from "../components/AuthLayout";
import { AuthContext } from "../../../AuthContext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import logo from "../../../assets/images/logo.svg";
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import colors from "assets/theme/base/colors";
import useApi from "../../../shared/const/api-backend";
import { useForm } from "react-hook-form";

import "./login-fctg.scss";
import { UserDataContext } from "../../../shared/context/userDataContext";
import { useTranslation } from "react-i18next";

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

function FctgLogin() {
  const { t } = useTranslation();
  const API = useApi();
  const navigate = useNavigate();
  const { saveToken } = useContext(AuthContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const { fetchDataCenterFromApi } = useContext(UserDataContext);
  const { white, buttons } = colors;
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    const { email, password } = getValues();
    if (!email || !password) {
      showNotification("COMMON.error.fillAllFields", "error");
      return;
    }
    setLoading(true);
    try {
      const url = `${apiStrapiUrl}auth/local`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          identifier: email,
          password: password,
        }),
      });
      if (!response.ok) {
        throw new Error("requestFailed");
      }
      const jsonResponse = await response.json();
      saveToken(jsonResponse.jwt, "fctg");
      fetchDataCenterFromApi();
      const meResponse = await API.get(`${apiStrapiUrl}users/me?populate=role`);

      if (meResponse.status !== 200) {
        throw new Error("requestFailed");
      }

      const brands = meResponse.data.brands;
      if (brands.length > 0) {
        showNotification("NOTIFICATION.success.login", "success");
        // @TODO temporary redirect to dashboard, shoule be welome page - ESHOP-173
        navigate("/dashoard");
        // navigate("/welcome-page");
      } else {
        throw new Error("noAssignedBrands");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.message === "requestFailed") {
        showNotification("COMMON.error.login", "error");
      } else if (error.message === "noAssignedBrands") {
        showNotification("COMMON.error.noAssignedBrands", "error");
      } else {
        showNotification("COMMON.error.fetchingData", "error");
      }
    }
  };

  const handleProviderLogin = (provider) => {
    window.location.href = `${apiStrapiUrl}connect/${provider}`;
  };

  return (
    <AuthLayout image={authFctgBackground}>
      <Card>
        <SoftBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            background: white.main,
            borderRadius: "16px",
            padding: "40px",
            width: "100%",
          }}
        >
          <Box
            component="img"
            src={logo}
            sx={{
              minWidth: "48%",
              mt: 2,
              mb: 4,
            }}
          />
          <Typography variant="h4" sx={{ mb: 2 }}>
            Flight Centre Portal
          </Typography>
          <SoftBox component="div" sx={{ mt: 3, width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ mb: 2 }}>
                <SoftBox sx={{ border: 1, borderRadius: 2 }}>
                  <SoftInput
                    type="email"
                    placeholder="Email"
                    autoComplete="email"
                    error={errors.email}
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        message: "Invalid email address",
                      },
                    })}
                  />
                </SoftBox>
                {errors.email && <span className="error-message">{errors.email.message}</span>}
              </Box>

              <Box sx={{ mb: 2 }}>
                <SoftBox sx={{ border: 1, borderRadius: 2 }}>
                  <SoftInput
                    type="password"
                    placeholder="Password"
                    autoComplete="current-password"
                    error={errors.password}
                    {...register("password", { required: "Password is required", email: true })}
                  />
                </SoftBox>
                {errors.password && (
                  <span className="error-message">{errors.password.message}</span>
                )}
              </Box>

              <SoftBox mt={2} mb={1}>
                <SoftButton
                  onClick={handleSubmit}
                  variant="gradient"
                  type="submit"
                  sx={{
                    mb: 1,
                    textTransform: "none",
                    backgroundColor: buttons.primary.background,
                    "&:hover": {
                      backgroundColor: buttons.primary.background,
                    },
                    color: buttons.primary.text,
                  }}
                  fullWidth
                >
                  {t("AUTH.LOGIN.login")}
                </SoftButton>
              </SoftBox>
            </form>
            <SoftBox className="divider">
              <SoftBox className="divider__line"></SoftBox>
              <SoftBox className="divider__text">{t("AUTH.LOGIN.or")}</SoftBox>
              <SoftBox className="divider__line"></SoftBox>
            </SoftBox>
            <SoftButton
              onClick={() => handleProviderLogin("microsoft")}
              variant="gradient"
              type="submit"
              sx={{
                textTransform: "none",
                backgroundColor: buttons.secondary.background,
                "&:hover": {
                  backgroundColor: buttons.secondary.background,
                },
                color: buttons.secondary.text,
                marginBottom: "0.5rem",
              }}
              fullWidth
            >
              {t("AUTH.LOGIN.azureLogin")}
            </SoftButton>
            <SoftButton
              onClick={() => handleProviderLogin("okta")}
              variant="gradient"
              type="submit"
              sx={{
                textTransform: "none",
                backgroundColor: buttons.green.background,
                "&:hover": {
                  backgroundColor: buttons.green.background,
                },
                color: buttons.secondary.text,
              }}
              fullWidth
            >
              {t("AUTH.LOGIN.oktaLogin")}
            </SoftButton>
            {/*<SoftBox component="form" role="form">*/}

            {/*  </SoftBox>*/}
            {/*</SoftBox>*/}
          </SoftBox>
        </SoftBox>
      </Card>
    </AuthLayout>
  );
}

export default FctgLogin;

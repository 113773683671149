import React from 'react';
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import MiniGradientLineChart from 'examples/Charts/LineCharts/MiniGradientLineChart';
import GradientLineChart from 'examples/Charts/LineCharts/GradientLineChart';
import { useTranslation } from 'react-i18next';
import CurrencyComponent from 'shared/components/Currency/Currency';

const PolicyRevenueWidget = ({ data, isOpened, openWidget, closeWidget }) => {
    const { t } = useTranslation();
    return isOpened ? (
        <Card
            className="dashboard-widget policy-revenue dashboard-widget--opened"
        >
            <SoftBox className='widget-header'>
                <SoftTypography className='widget-header__title'>{t('HOME.DASHBOARD.widgets.revenue')}</SoftTypography>
                <SoftTypography onClick={() => closeWidget()} className='widget-header__button'>{t('HOME.DASHBOARD.widgets.minimize')}</SoftTypography>
            </SoftBox>
            <GradientLineChart
                chart={data.chart}
            />
            <SoftBox className='widget-footer'>
                <SoftBox className='widget-footer__text'>
                    <SoftBox component="p">
                        {t('HOME.DASHBOARD.widgets.totalRevenue')}:
                    </SoftBox>
                    <SoftBox component="span">
                        <CurrencyComponent value={data.total}
                            countryCode={data.currency}></CurrencyComponent>
                    </SoftBox>
                </SoftBox>
                <SoftBox component="span" className={`widget-footer__trend ${data.trend === 'decrease' ? 'widget-footer__trend--decrease' : ''}`}>({data.trend === 'decrease' ? '-' : '+'}{data.diffPercentage}%)</SoftBox>
            </SoftBox>
        </Card>
    ) :
        (
            <Card
                className="dashboard-widget policy-revenue dashboard-widget--closed"
            >
                <SoftBox className='widget-header'>
                    <SoftTypography className='widget-header__title'>{t('HOME.DASHBOARD.widgets.revenue')}</SoftTypography>
                    <SoftTypography onClick={() => openWidget()} className='widget-header__button'>{t('HOME.DASHBOARD.widgets.maximize')}</SoftTypography>
                </SoftBox>
                <SoftBox className="gradient-line-chart">
                    <MiniGradientLineChart
                        height="14rem"
                        chart={data.chart}
                    />
                </SoftBox>
                <SoftBox className='widget-footer'>
                    <SoftBox className='widget-footer__text'>
                        <SoftBox component="p">
                            {t('HOME.DASHBOARD.widgets.totalRevenue')}:
                        </SoftBox>
                        <SoftBox component="span">
                            <CurrencyComponent value={data.total}
                                countryCode={data.currency}></CurrencyComponent>
                        </SoftBox>
                        <SoftBox component="span" className={`widget-footer__trend ${data.trend === 'decrease' ? 'widget-footer__trend--decrease' : ''}`}>({data.trend === 'decrease' ? '-' : '+'}{data.diffPercentage}%)</SoftBox>
                    </SoftBox>
                </SoftBox>
            </Card>
        )
}

PolicyRevenueWidget.propTypes = {
    data: PropTypes.shape({
        chart: PropTypes.any.isRequired,
        total: PropTypes.number.isRequired,
        trend: PropTypes.string.isRequired,
        diffPercentage: PropTypes.number.isRequired,
        currency: PropTypes.string.isRequired,
    }).isRequired,
    data: PropTypes.any.isRequired,
    isOpened: PropTypes.bool,
    openWidget: PropTypes.func,
    closeWidget: PropTypes.func,
};


export default PolicyRevenueWidget;

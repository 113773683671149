import { useContext, useEffect, useState } from "react";
import { LoaderContext } from "../../../shared/context/loaderContext";
import { NotificationContext } from "../../../shared/context/notificationContext";
import {CONFIG_TABLE} from "../../../shared/const/app.config";
import useApi from "../../../shared/const/api-backend";
import { UserDataContext } from "shared/context/userDataContext";
import { reportError } from "shared/services/raygunService";

const apiStrapiUrl = process.env.REACT_APP_STRAPI_API;

const useFetchQuotesData = () => {
  const { dataCenter } = useContext(UserDataContext);
  const { setLoading } = useContext(LoaderContext);
  const { showNotification } = useContext(NotificationContext);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const API = useApi();

  const fetchData = async (quoteId) => {
    setLoading(true);
    try {
      let apiUrl = `${apiStrapiUrl}quotes?offset=0&limit=${CONFIG_TABLE.MAX_ROWS_FROM_BACKEND}`;
      if (quoteId) {
        apiUrl += `&filter=${quoteId}`;
      }

      const response = await API.get(apiUrl);
      setData(response.data);
    } catch (error) {
      showNotification("COMMON.error.fetchingData", "error");
      reportError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dataCenter]);

  return { data, error, fetchData };
};

export default useFetchQuotesData;
